@import '../../Styles/variables.scss';

.applicationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .65);
    z-index: 2;
    .applicationForm {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        width: 90%;
        padding: 2rem;
        background: $white;
        text-align: left;
        @media only screen and (min-width: $tablet) {
            width: 50%;
            max-width: 600px;
            padding: 4rem;
        }
        .close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            width: 26px;
            height: 26px;
            cursor: pointer;
            &:hover {
                span {
                    background: $primaryColor;
                }
            }
            span {
                position: absolute;
                top: 12px;
                width: 26px;
                height: 4px;
                background: $baseColor;
                border-radius: 2px;
                &:nth-of-type(1) {
                    transform: rotate(45deg);
                }
                &:nth-of-type(2) {
                    transform: rotate(-45deg);
                }
            }
        }
        .roleTitle {
            font-size: 1.4rem;
            font-weight: 600;
            color: $baseColor;
        }
        .location {
            font-weight: 600;
            padding-bottom: 2rem;
        }
        label {
            margin-bottom: .25rem;
            margin-top: 1.5rem;
            font-size: .75rem;
            font-weight: 600;
            text-transform: uppercase;
            &:nth-of-type(1) {
                margin-top: 0;
            }
            &:after {
                content: '*';
                position: relative;
                left: 4px;
                color: $primaryColor;
            }
        }
        input {
            width: 100%;
            padding: .5rem;
            outline: none;
            font-size: 1rem;
            border-radius: 0;
            -webkit-appearance: none;
            &:focus {
                border: 2px solid $primaryColor;
            }
        }
        .fileInputContainer {
            position: relative;
            .fileInput {
                position: absolute;
                opacity: 0;
                margin: 0;
                height: 100%;
                cursor: pointer;
                &:hover + .fileBtn{
                    border: .25rem solid $primaryColor;
                }
            }
        }
        button {
            display: flex;
            border: none;
            font-size: 1rem;
            font-weight: 700;
            cursor: pointer;
            &.fileBtn {
                padding: .75rem 1.75rem;
                background: $white;
                border: .25rem solid $baseColor;
                color: $baseColor;
                transition: all .3s ease;
            }
            &.applyBtn {
                margin-top: 2rem;
                padding: 1rem 2rem;
                background: $baseColor;
                color: $white;
                transition: all .3s ease;
                &:hover {
                    background: $primaryColor;
                }
            }
        }
    }
}