@import "../../Styles/variables.scss";

.mobileMenuContainer {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100vh;
    background: $white;
    z-index: 1;
    &.show {
        display: flex;
    }
    @media only screen and (min-width: $desktop) {
        display: none;
    }
    ul {
        text-align: center;
        li {
            padding: 1rem 0;
            list-style: none;
            font-size: 1.5rem;
            a {
                text-decoration: none;
                color: $baseColor;
            }
        }
    }
}