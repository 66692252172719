// Colors
$baseColor: #272A5C;
$primaryColor: #B328B5;
$white: #FFF;
$offWhite: #FAFAFA;
$grey: #F0F0F0;
$darkGrey: #525252;
$darkestGrey: #313131;

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

$primaryFont: 'Open Sans', sans-serif;

// Breakpoints
$smartphone: 500px;
$tablet: 768px;
$desktop: 1024px;

// Spacing
$verticleMargin: 10%;