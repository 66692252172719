@import '../../Styles/variables.scss';

.applyNowBtn {
    width: 115px;
    padding: 1rem 0;
    color: #FFF;
    background: $baseColor;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all .5s ease;
    &:hover {
        background: $primaryColor;
    }
}