@import "../../Styles/variables.scss";

.halfblockContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media only screen and (min-width: $desktop) {
        flex-direction: row-reverse;
        height: 60vh;
    }
    &.reverse {
        flex-direction: column;
        @media only screen and (min-width: $desktop) {
            flex-direction: row;
            height: 60vh;
        }
    }
    .copyContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 6rem 0;
        background: $white;
        color: $baseColor;
        font-weight: 600;
        @media only screen and (min-width: $desktop) {
            width: 50%;
            padding: 0;
        }
        p {
            max-width: 80%;
            margin-top: 1rem;
            @media only screen and (min-width: $desktop) {
                max-width: 60%;
            }
        }
        .learnMoreLink {
            text-decoration: none;
            color: $primaryColor;
        }
    }
    .imageContainer {
        position: relative;
        width: 100%;
        height: 40vh;
        background-size: cover;
        overflow: hidden;
        @media only screen and (min-width: $desktop) {
            width: 50%;
            height: auto;
        }
        &:hover {
            .imageOverlay {
                transform: translateX(0);
            }
        }
        .imageOverlay {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background: rgba(179, 40, 181, .7);
            transition: all .3s ease;
            @media only screen and (min-width: $desktop) {
                transform: translateX(-100%);
            }
            &:after {
                content: '';
                position: absolute;
                right: -50px;
                width: 50px;
                height: 50px;
                background-color: rgba(179, 40, 181, .7);
                background-image: url('../../images/arrow.svg');
                background-size: 30px;
                background-repeat: no-repeat;
                background-position: center;
            }
            .learnMoreBtn {
                display: flex;
                align-items: center;
                position: relative;
                padding: 1rem 2rem;
                background: none;
                border: 2px solid $white;
                color: $white;
                font-size: 1rem;
                font-weight: 600;
                cursor: pointer;
                &:after {
                    content: '';
                    position: relative;
                    left: .5rem;
                    width: 25px;
                    height: 25px;
                    background: url('../../images/arrow.svg');
                    transition: all .3s ease;
                }
                &:hover {
                    border-width: 3px;
                    &:after {
                        left: 1rem;
                    }
                }
            }
        }
    }
    &.reverse { 
        .imageContainer {
            &:hover {
                .imageOverlay {
                    transform: translateX(0);
                }
            }
            .imageOverlay {
                @media only screen and (min-width: $desktop) {
                    transform: translateX(100%);
                }
                &:after {
                    left: -50px;
                    transform: rotate(180deg);
                }
            }
        }
    }
}