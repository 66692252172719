@import './variables.scss';
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body {
    font-family: $primaryFont;
    overflow-x: hidden;
}
h1,
h2 {
    font-weight: 700;
}
h1 {
    font-size: 2.5rem;
    @media only screen and (min-width: $tablet) {
        font-size: 3.5rem;
    }
}
h2 {
    font-size: 2rem;
    @media only screen and (min-width: $tablet) {
        font-size: 3rem;
    }
}
h3 {
    font-weight: 600;
}
a {
    text-decoration: none;
}
.gutter {
    padding-left: 5%;
    padding-right: 5%;
    @media only screen and (min-width: $tablet) {
        padding-left: 10%;
        padding-right: 10%;
    }
    @media only screen and (min-width: $desktop) {
        padding-left: 15%;
        padding-right: 15%;
    }
}
.errorMessage {
    margin-top: 2px;
    text-align: left;
    font-size: 12px;
    color: red;
}
.successMessage {
    align-self: flex-start;
    margin: .75rem 0;
    color: rgb(34, 112, 38);
}

.loadingContainer {
    position: relative;
    width: 28px;
    height: 18px;
    margin-left: 12px;
    transform: translateY(-2px);
    overflow: hidden;
    span {
        position: absolute;
        bottom: 0;
        width: 4px;
        background: $white;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        &:nth-of-type(1) {
            left: 0;
            height: 18px;
            transform: translateY(0);
            animation-name: barAnimation1;
            animation-duration: .7s;
        }
        &:nth-of-type(2) {
            left: 8px;
            height: 14px;
            transform: translateY(0);
            animation-name: barAnimation2;
            animation-duration: .5s;
        }
        &:nth-of-type(3) {
            left: 16px;
            height: 10px;
            transform: translateY(0);
            animation-name: barAnimation3;
            animation-duration: .6s;
        }
        &:nth-of-type(4) {
            left: 24px;
            height: 16px;
            transform: translateY(0);
            animation-name: barAnimation4;
            animation-duration: .4s;
        }
    }
}

@keyframes barAnimation1 {
    from {
        transform: translateY(0);
      }
      to {
        transform: translateY(80%);
        }
}

@keyframes barAnimation2 {
    from {
        transform: translateY(0);
      }
      to {
        transform: translateY(60%);
        }
}

@keyframes barAnimation3 {
    from {
        transform: translateY(0);
      }
      to {
        transform: translateY(70%);
        }
}

@keyframes barAnimation4 {
    from {
        transform: translateY(0);
      }
      to {
        transform: translateY(80%);
        }
}