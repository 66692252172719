@import "../../Styles/variables.scss";

.contactContainer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    padding-top: 4rem;
    padding-bottom: 6rem;
    background: $offWhite;
    @media only screen and (min-width: $desktop) {
        flex-direction: row;
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
    .formContainer,
    .copyContainer {
        display: flex;
        width: 100%;
        @media only screen and (min-width: $desktop) {
            display: flex;
            width: 44%;
        }
    }
    .formContainer {
        form {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 2rem;
            background: $white;
            box-shadow: 0 0 50px rgba(0, 0, 0, .1);
            & > * {
                margin: .75rem 0;
            }
            .formRow {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                margin: 0;
                @media only screen and (min-width: $smartphone) {
                    flex-direction: row;
                    margin: .75rem 0;
                }
                .inputGroup {
                    width: 100%;
                    margin: .75rem 0;
                    @media only screen and (min-width: $smartphone) {
                        flex-grow: 0;
                        width: 48%;
                        margin: 0;
                    }
                }
            }
            .inputGroup {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                flex-grow: 1;
                label {
                    margin-bottom: .25rem;
                    font-size: .75rem;
                    font-weight: 500;
                    text-transform: uppercase;
                    &.required {
                        &:after {
                            content: '*';
                            position: relative;
                            left: 4px;
                            color: $primaryColor;
                        }
                    }
                }
                input,
                textarea {
                    width: 100%;
                    padding: 8px;
                    outline: none;
                    font-family: $primaryFont;
                    font-size: 16px;
                    border: 1px solid #616161;
                    border-radius: 0;
                    -webkit-appearance: none;
                    @media only screen and (min-width: $desktop) {
                        font-size: 14px;
                    }
                    &:focus {
                        padding: 7px;
                        border: 2px solid $primaryColor;
                    }
                }
                textarea {
                    height: 100px;
                }
            }
            button {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-grow: 1;
                padding: 1rem 2rem;
                background: $baseColor;
                border: 0;
                outline: none;
                font-size: 1rem;
                font-weight: 600;
                color: $white;
                cursor: pointer;
                transition: all .3s ease;
                @media only screen and (min-width: $smartphone) {
                    align-self: flex-start;
                }
                &:hover {
                    background: $primaryColor;
                }
            }
        }
    }
    .copyContainer {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-bottom: 4rem;
        @media only screen and (min-width: $desktop) {
            margin-bottom: 0;
        }
        h2 {
            margin-bottom: 2rem;
        }
        ul {
            margin-top: 2rem;
            li {
                margin-bottom: 1rem;
                list-style: none;
            }
        }
    }
}