@import "../../Styles/variables.scss";

.headerContainer {
    &.transparent {
        background: transparent;
        ul {
            li {
                &.roundBtn {
                    background: $baseColor;
                    border-radius: 20px;
                    font-weight: 500;
                    a {
                        color: $white;
                    }
                }
                a {
                    text-decoration: none;
                    color: $white;
                    &.isActive {
                        color: $primaryColor;
                    }
                }
            }
        }
    }
    img {
        width: 170px;
        height: auto;
        @media only screen and (min-width: $smartphone) {
            width: 200px;
            height: auto;
        }
    }
    ul {
        display: none;
        li {
            padding: 8px 14px;
            list-style: none;
            &.roundBtn {
                background: $baseColor;
                border-radius: 20px;
                font-weight: 500;
                cursor: pointer;
                transition: .25s;
                &:hover {
                    background: $primaryColor;
                    box-shadow: 0 10px 12px rgba(0, 0, 0, .1);
                    transform: translateY(-2px);
                }
                a {
                    color: $white;
                }
            }
            a {
                text-decoration: none;
                color: $baseColor;
                &.isActive {
                    color: $primaryColor;
                }
            }
        }
        @media only screen and (min-width: $desktop) {
            display: inline-flex;
        }
    }
    .hamburgerContainer {
        display: block;
        position: relative;
        width: 28px;
        height: 28px;
        overflow: hidden;
        cursor: pointer;
        &.blue {
            span {
                background: $baseColor;
            }
        }
        @media only screen and (min-width: $desktop) {
            display: none;
        }
        span {
            position: absolute;
            height: 4px;
            background: $white;
            border-radius: 2px;
            transition: all .3s ease;
            &:nth-last-of-type(1) {
                width: 18px;
                top: 0;
                right: 0;
            }
            &:nth-last-of-type(2) {
                width: 24px;
                top: 12px;
                right: 0;
            }
            &:nth-last-of-type(3) {
                width: 14px;
                top: 24px;
                right: 0;
            }
        }
        &.open {
            span {
                &:nth-last-of-type(1) {
                    top: 14px;
                    width: 28px;
                    transform: rotate(45deg);
                }
                &:nth-last-of-type(2) {
                    width: 26px;
                    right: -26px;
                }
                &:nth-last-of-type(3) {
                    top: 14px;
                    width: 28px;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}